import { QrCodeIcon } from "@heroicons/react/20/solid";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Card,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@mindstonehq/ui";
import { useConfig } from "~/hooks/webconfig";

export default function ProfileQRCode({
  profile,
}: {
  profile: {
    profilePictureURL?: string | null;
    displayName: string;
    username: string;
    profileUrl?: string | null;
  };
}) {
  const config = useConfig();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          <QrCodeIcon className="h-4 w-4 cursor-pointer" />
        </Button>
      </DialogTrigger>
      <DialogContent
        className={"max-w-3xl w-full min-h-[100dvh] md:min-h-auto"}
      >
        <div className="flex-1 flex flex-col justify-center items-center space-y-4">
          <Card className="bg-white w-full text-center flex flex-col items-center p-8 relative">
            <Avatar className="h-12 w-12 absolute -top-6">
              <AvatarImage src={profile.profilePictureURL || ""} />
              <AvatarFallback>
                {profile.username.substring(0, 2)}
              </AvatarFallback>
            </Avatar>
            <div className="flex flex-col items-center mb-8">
              <div className="text-slate-800">{profile.displayName}</div>
              <div className="text-slate-600 text-sm">{profile.username}</div>
            </div>
            <QRCodeSVG
              value={
                profile.profileUrl ||
                `${config.appUrl}/profile/${profile.username}`
              }
              size={200}
            />
          </Card>
          <div className="text-placeholder-foreground text-center text-sm">
            With just a scan, step into events and seamlessly share your
            profile. Connect and grow your network effortlessly.
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
